
import { Vue, Component } from "vue-property-decorator";
import { UserService } from "@/api/UserServiceApi";

@Component
export default class EmailVerification extends Vue {
  async created() {
    let code = this.$route.query.code as string;
    if (code == null || code.length == 0) {
      await this.$router.push("/");
    }

    await UserService.VerifyEmail(code)
      .then(async () => {
        this.$swal.fire({
          titleText: this.$t("MODAL.VERIFICATION_MESSAGE.SUCCESS").toString(),
          confirmButtonText: "Ok",
          icon: "success"
        });
      })
      .catch(() => {
        this.$swal.fire({
          titleText: this.$t("MODAL.VERIFICATION_MESSAGE.ERROR").toString(),
          confirmButtonText: "Ok",
          icon: "error"
        });
      });

    await this.$router.push("/dashboard");
  }
}
